import {
  usePeopleQuery,
  useOrganizationTagsQuery
} from '../../../shared/queryHooks'
import { useAuthContext } from '../../../context/AuthContext'
import { useModal } from '../../../context/ModalContext'
import { logError } from '../../../shared/logger'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import PeopleTable from '../../../components/PeopleTable'
import LoadingScreen from '../../../components/LoadingScreen'
import InvitePeople from '../../../modals/InvitePeople'
import './index.css'

function WorkspaceSettingsPeople () {
  const { makeModal } = useModal()
  const { currentUser } = useAuthContext()
  const {
    isLoading,
    isError,
    data: people,
    error
  } = usePeopleQuery(currentUser?.currentOrganizationId ?? 0, {
    enabled: !!currentUser
  })
  const {
    isLoading: isTagsLoading,
    isError: isTagsError,
    data: tags,
    error: tagsError
  } = useOrganizationTagsQuery({
    organizationId: currentUser?.currentOrganizationId,
    options: { enabled: !!currentUser && !!people }
  })

  if (isLoading || isTagsLoading) {
    return (
      <>
        <SecondaryNavBar title="People" />
        <LoadingScreen />
      </>
    )
  }

  if (isError || isTagsError) {
    logError(error || tagsError)
    return <SecondaryNavBar title="People" />
  }

  return (
    <>
      <SecondaryNavBar
        title="People"
        onClickActions={[
          {
            action: () =>
              makeModal({
                modal: <InvitePeople />,
                title: 'Add People',
                enableBackdropClose: false,
                modalSize: 'LARGE',
                disableFocus: true
              }),
            actionTitle: 'Add People',
            actionClassName: 'secondary'
          }
        ]}
      />
      <div className="people-container">
        <PeopleTable
          data={people || []}
          tags={Array.isArray(tags) ? tags.map((tag) => tag?.name ?? '') : []}
        />
      </div>
    </>
  )
}

export default WorkspaceSettingsPeople
