import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useState, useRef } from 'react'
import SearchInput from '../SearchInput'
import { Checkbox } from '@mui/material'
import ManagePeopleMenu from '../ManagePeopleMenu'
import { OrganizationRole } from '../../types'
import SortPeopleTags from '../SortPeopleTags'

interface Column {
  header: string;
  id?: string;
  accessorFn?: (row: any) => any;
  cell?: (props: { row: any }) => JSX.Element | string;
  enableSorting?: boolean;
}

interface TableProps<T> {
  data: T[];
  tags?: string[];
  handleSort?: (selectedTags: string[]) => void;
  columns: Column[];
  typeReferenceText: string;
  fuzzyFilter?: (row: any, columnId: string, value: string) => boolean;
  requiresSearch?: boolean;
  tableWrapperClass: string;
  displaySelect?: boolean;
  selectAll?: boolean;
  selectedTags?: string[];
  setSelectedTags?: (selectedTags: string[]) => void;
  handleSelectAll?: (selectOption: boolean) => void;
  selectedOrganizationRoles?: number[];
}

function Table<T> ({
  data,
  tags,
  handleSort,
  columns,
  fuzzyFilter,
  typeReferenceText,
  requiresSearch,
  tableWrapperClass,
  displaySelect,
  selectAll,
  handleSelectAll,
  selectedOrganizationRoles,
  selectedTags,
  setSelectedTags
}: TableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = useState<string>('')

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel()
  })

  const { rows } = table.getRowModel()

  const parentRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: 20
  })

  return (
    <>
      {(requiresSearch == null || requiresSearch) && (
        <div className="person-input-container">
          <SearchInput
            onSearchChanged={(searchText) => setGlobalFilter(searchText)}
            placeholder={`Search ${typeReferenceText}...`}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          marginLeft: '2rem',
          alignItems: 'center'
        }}
      >
        {displaySelect && (
          <div
            style={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
          >
            <Checkbox
              checked={!!selectAll}
              onChange={() => handleSelectAll && handleSelectAll(!selectAll)}
            />
            <div>Select All</div>
          </div>
        )}
        {selectedOrganizationRoles && selectedOrganizationRoles?.length > 0 && (
          <ManagePeopleMenu
            organizationRoles={
              data.filter((role) =>
                selectedOrganizationRoles.includes(
                  (role as OrganizationRole).id
                )
              ) as OrganizationRole[]
            }
          />
        )}
        {tags && tags.length > 0 && (
          <SortPeopleTags
            tags={tags}
            handleSort={handleSort}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        )}
      </div>
      <div className={tableWrapperClass} ref={parentRef}>
        {data?.length
          ? (
          <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
            <table className="people">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup?.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header?.id} colSpan={header.colSpan}>
                          {!header?.isPlaceholder && (
                            <div
                              {...{
                                className: header?.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : 'display-none',
                                onClick:
                                  header?.column.getToggleSortingHandler()
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} style={{ height: '56px' }}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
                {table.getRowModel().rows?.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      <div className="no-people">
                        <div className="no-people-text">
                          No {typeReferenceText} Found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
            )
          : (
          <div>No {typeReferenceText} found</div>
            )}
      </div>
    </>
  )
}

export default Table
